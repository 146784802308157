// import external dependencies
import $ from 'jquery';
import Swiper from "swiper";

document.addEventListener("DOMContentLoaded", function() {

  var jobGallery = $('.jobSwiper');
    var jobSwiper = new Swiper(jobGallery, {
      spaceBetween: 20,
      slidesPerView: 3,
      grabCursor: true,
      autoHeight: false,
      draggable: true,
      autoplay: {
        delay: 2500,
      },
      // freeMode: true,
      breakpoints: {
        // when window width is <= 320px
        620: {
          slidesPerView: 1,
        },
        // when window width is <= 480px
        // when window width is <= 640px
        940: {
          slidesPerView: 2,
      }
    },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })


});